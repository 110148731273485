export const quantiacs = {
    homePage: {
        siteName: 'quantiacs',
        logUrl: 'https://www.quantiacs.com/QuantNetSite/media/BindTuning-SPCTheme/quantiacs-logo.png?width=283&height=63&ext=.png',

        contacts: {
            emailSupport: {
                name: 'Email',
                title: 'info@quantiacs.com',
                link: 'mailto:info@quantiacs.com',
            },

            facebook: {
                name: 'Facebook',
                title: 'Facebook',
                link: 'https://www.facebook.com/quantiacs/',
            },

            twitter: {
                name: 'Twitter',
                title: 'Twitter',
                link: 'https://twitter.com/Quantiacs',
            },

            linkedin: {
                name: 'Linkedin',
                title: 'Linkedin',
                link: 'https://www.linkedin.com/company/quantiacs',
            },

            youtube: {
                name: 'Youtube',
                title: 'Youtube',
                link: 'https://www.youtube.com/channel/UChVK03CwYHSXwsBiPuSi9GA',
            },
        },
    },
    general: {
        currency: 'USD',
        logoClassName: 'quantiacs-logo',
    },

    account: {
        contacts: {
            gitHub: {
                name: 'GitHub',
                title: 'quantiacs',
                link: 'https://github.com/quantiacs',
            },
            emailSupport: {
                name: 'Email',
                title: 'info@quantiacs.com',
                link: 'mailto:info@quantiacs.com',
            },
        },
    },
    recaptcha: '6LemyjEmAAAAAKqajJUnxmhThU-svok6MxCcuZcP',
};
