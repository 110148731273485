import React from 'react';
import '../../css/style.scss';
import styled from 'styled-components';

const AboutWinnersDiv = styled.div`
    .fixed_col {
        min-width: 124px;
    }
`;
const AboutContestsDiv = styled.div`
    font-size: 1.2em;
    line-height: 1.2em;
    max-width: 1210px;
    margin: 20px auto;
    padding-left: 15px;
    padding-right: 15px;

    color: rgb(168, 160, 149);

    strong {
        font-weight: bold;
    }

    .fixed_col {
        min-width: 120px;
    }
`;

function AboutWinners() {
    return (
        <AboutWinnersDiv className="quantiacs_in_the_press">
            <section className="middle_theme">
                <h2 className="section_header">Contests winners</h2>

                <AboutContestsDiv>
                    Since 2014 Quantiacs has hosted quantitative trading contests and has allocated
                    more than <strong>38M USD</strong> to winning algorithms on futures markets.
                </AboutContestsDiv>
                <div className="competitions_results_competition_all">
                    <table>
                        <thead>
                            <tr>
                                <th>Contest</th>
                                <th>Gold (1M USD)</th>
                                <th>Silver (750k USD)</th>
                                <th>Bronze (500k USD)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <a href="https://legacy.quantiacs.com/Competitions/KickoffContestRanking.aspx">
                                        Q0
                                    </a>
                                </td>
                                <td>Yi-Shu</td>
                                <td>DavidM</td>
                                <td>SukruE</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="https://legacy.quantiacs.com/Competitions/Futures-Contest-Ranking.aspx">
                                        Q1
                                    </a>
                                </td>
                                <td>Teagan</td>
                                <td>SercanA</td>
                                <td>Yi-Shu</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="https://legacy.quantiacs.com/Competitions/Q2.aspx">
                                        Q2
                                    </a>
                                </td>
                                <td>Timeroot</td>
                                <td>Incognition</td>
                                <td>davmont/JesseK</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="https://legacy.quantiacs.com/Competitions/Q3.aspx">
                                        Q3
                                    </a>
                                </td>
                                <td>Jenia</td>
                                <td>Timeroot</td>
                                <td>Phidias</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="https://legacy.quantiacs.com/Competitions/Q4.aspx">
                                        Q4
                                    </a>
                                </td>
                                <td>DrKodiak</td>
                                <td>Ajackson</td>
                                <td>s_poramet</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="https://legacy.quantiacs.com/Competitions/Q5.aspx">
                                        Q5
                                    </a>
                                </td>
                                <td>DrKodiak</td>
                                <td>Jenia</td>
                                <td>kviggy0</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="https://legacy.quantiacs.com/Competitions/Q6.aspx">
                                        Q6
                                    </a>
                                </td>
                                <td>DrKodiak</td>
                                <td>Timeroot</td>
                                <td>kviggy0</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="https://legacy.quantiacs.com/Competitions/Q7.aspx">
                                        Q7
                                    </a>
                                </td>
                                <td>DrKodiak</td>
                                <td>Timeroot</td>
                                <td>Jenia</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="https://legacy.quantiacs.com/Competitions/Q8.aspx">
                                        Q8
                                    </a>
                                </td>
                                <td>danielkscarmo</td>
                                <td>durasm</td>
                                <td>AzureFractal</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="https://legacy.quantiacs.com/Competitions/Q9.aspx">
                                        Q9
                                    </a>
                                </td>
                                <td>danielkscarmo</td>
                                <td>s_poramet</td>
                                <td>charless</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="https://legacy.quantiacs.com/Competitions/Q10.aspx">
                                        Q10
                                    </a>
                                </td>
                                <td>Sun73</td>
                                <td>dm7</td>
                                <td>mwalimudan</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="https://legacy.quantiacs.com/Competitions/Q11.aspx">
                                        Q11
                                    </a>
                                </td>
                                <td>charless</td>
                                <td>stevefoeldvari</td>
                                <td>s_poramet</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="https://legacy.quantiacs.com/Competitions/Q12.aspx">
                                        Q12
                                    </a>
                                </td>
                                <td>s_poramet</td>
                                <td>dm7</td>
                                <td>Sun73</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="https://legacy.quantiacs.com/Competitions/Q13.aspx">
                                        Q13
                                    </a>
                                </td>
                                <td>charless</td>
                                <td>s_poramet</td>
                                <td>dm7</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="https://legacy.quantiacs.com/Competitions/Q14.aspx">
                                        Q14
                                    </a>
                                </td>
                                <td>Sun73</td>
                                <td>stevefoeldvari</td>
                                <td>antinomy</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <AboutContestsDiv>
                    Since 2021, Quantiacs has been hosting quantitative trading contests for
                    predicting futures, cryptocurrencies, and stocks.
                </AboutContestsDiv>
                <div className="competitions_results_competition_all">
                    <table>
                        <thead>
                            <tr>
                                <th className="fixed_col">Contest</th>
                                <th className="fixed_col">1M USD</th>
                                <th className="fixed_col">500k USD</th>
                                <th className="fixed_col">250k USD</th>
                                <th className="fixed_col">100k USD</th>
                                <th className="fixed_col">50k USD</th>
                                <th className="fixed_col">50k USD</th>
                                <th className="fixed_col">50k USD</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <a href="/leaderboard/15">Q15 Futures</a>
                                </td>
                                <td>kvanvanvant_test_python</td>
                                <td>lookman</td>
                                <td>Sun-73</td>
                                <td>Algotime</td>
                                <td>EmilianoFraticelli</td>
                                <td>raider512</td>
                                <td>jofre44</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="/leaderboard/15">Q15 Crypto</a>
                                </td>
                                <td>anshul96go</td>
                                <td>Sun-73</td>
                                <td>lookman</td>
                                <td>0mgsyst3ms</td>
                                <td>mwalimudan</td>
                                <td>vg2001</td>
                                <td>TheFlyingDutchman</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="/leaderboard/16">Q16 Crypto</a>
                                </td>
                                <td>marcusxinho</td>
                                <td>quantinomy</td>
                                <td>mwalimudan</td>
                                <td>anhbt41</td>
                                <td>StayteMagic</td>
                                <td>EmilianoFraticelli</td>
                                <td>vsujith</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="/leaderboard/17">Q17 Crypto</a>
                                </td>
                                <td>RobProfit</td>
                                <td>marcusxinho</td>
                                <td>quantinomy</td>
                                <td>kvanvanvant_test_python</td>
                                <td>Algotime</td>
                                <td>mwalimudan</td>
                                <td>hma1983</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="/leaderboard/18">Q18 Nasdaq</a>
                                </td>
                                <td>lookman</td>
                                <td>raider512</td>
                                <td>kvanvanvant_test_python</td>
                                <td>0mgsyst3ms</td>
                                <td>Algotime</td>
                                <td>jofre44</td>
                                <td>Sun-73</td>
                            </tr>

                            <tr>
                                <td>
                                    <a href="/leaderboard/19">Q19 Nasdaq</a>
                                </td>
                                <td>Algotime</td>
                                <td>RobProfit</td>
                                <td>kvanvanvant_test_python</td>
                                <td>EDDIEE</td>
                                <td>jofre44</td>
                                <td>TheFlyingDutchman</td>
                                <td>Sun-73</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="/leaderboard/20">Q20 Nasdaq</a>
                                </td>
                                <td colSpan="7">
                                    <a href="/leaderboard">Running</a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="/leaderboard/21">Q21 Nasdaq</a>
                                </td>
                                <td colSpan="7">
                                    <a href="/leaderboard">Running</a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="/leaderboard/22">Q22 S&P500</a>
                                </td>
                                <td colSpan="7">
                                    <a href="/leaderboard">Running</a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="/contest">Q23 S&P500</a>
                                </td>
                                <td colSpan="7">
                                    <a href="/personalpage/login">Join the Contest</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </AboutWinnersDiv>
    );
}

export default AboutWinners;
