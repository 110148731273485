import React, {useState, useEffect, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser} from '@fortawesome/free-regular-svg-icons';
import MobileMenu from './MobileMenu';
import Menu from './Menu';
import {LanguageConsumer, stringsMenu} from '../Localisation';
import {getCookie, setCookie} from '../cookies';
import PlatformConfigurator from '../0_configuration/PlatformConfigurator';

function TranslatableText({textKey}) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsMenu.setLanguage(language);
                return stringsMenu[textKey];
            }}
        </LanguageConsumer>
    );
}

function Header() {
    const [authorities, setAuthorities] = useState([]);
    const [username, setUsername] = useState('');
    const [signInDisplay, setSignInDisplay] = useState('block');
    const [usernameDisplay, setUsernameDisplay] = useState('none');
    const MAX_ATTEMPTS = 3;
    const delay = (ms) =>
        new Promise((resolve) => {
            setTimeout(resolve, ms);
        });

    const clearAuth = useCallback(() => {
        setCookie('access_token', '', 0);
        setCookie('refresh_token', '', 0);
        setCookie('username', '', 0);
        setCookie('color', '', 0);
        setCookie('qn_uid', '', 0);
        setUsername('');
        setAuthorities([]);
        setSignInDisplay('block');
        setUsernameDisplay('none');
    }, []);

    const fetchAccountWithRetry = useCallback(
        async (data, rememberMe, attempt = 0) => {
            if (!navigator.onLine) {
                await new Promise((resolve) => {
                    window.addEventListener('online', resolve, {once: true});
                });
            }
            try {
                const response = await fetch('/auth/account/me', {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${data.access_token}`,
                        'Content-Type': 'application/json;charset=UTF-8',
                    },
                });
                if (response.status === 401 || response.status === 403)
                    throw new Error('Auth error');
                if (!response.ok) throw new Error('Server error');
                const accountData = await response.json();
                setCookie('access_token', data.access_token, data.expires_in);
                setCookie('username', accountData.username, data.expires_in);
                const tenDays = 10 * 24 * 60 * 60;
                if (rememberMe) setCookie('refresh_token', data.refresh_token, tenDays);
                setUsername(accountData.username);
                setAuthorities(accountData.authorities);
                setSignInDisplay('none');
                setUsernameDisplay('flex');
                return accountData;
            } catch (err) {
                if (err.name === 'AbortError') return null;
                if (err.message === 'Auth error') {
                    clearAuth();
                    return null;
                }
                if (attempt < MAX_ATTEMPTS) {
                    await delay(2 ** attempt * 1000);
                    const r = await fetchAccountWithRetry(data, rememberMe, attempt + 1);
                    return r;
                }
                console.error(err);
            }
            return null;
        },
        [clearAuth],
    );

    const exchangeTokenWithRetry = useCallback(
        async (refreshToken, attempt = 0) => {
            if (!navigator.onLine) {
                await new Promise((resolve) => {
                    window.addEventListener('online', resolve, {once: true});
                });
            }

            try {
                const response = await fetch('/oauth/token', {
                    method: 'POST',
                    headers: {
                        Authorization: 'Basic Y2xpZW50OnNlY3JldA==',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: `grant_type=refresh_token&refresh_token=${encodeURIComponent(
                        refreshToken,
                    )}&scope=read,write,trust`,
                });
                if (response.status === 401 || response.status === 403)
                    throw new Error('Auth error');
                if (!response.ok) throw new Error('Server error');
                const data = await response.json();
                await fetchAccountWithRetry(data, true);
            } catch (err) {
                if (err.name === 'AbortError') return null;
                if (err.message === 'Auth error') {
                    clearAuth();
                    return null;
                }
                if (attempt < MAX_ATTEMPTS) {
                    await delay(2 ** attempt * 1000);
                    const r = await exchangeTokenWithRetry(refreshToken, attempt + 1);
                    return r;
                }
                console.error(err);
            }
            return null;
        },
        [fetchAccountWithRetry, clearAuth],
    );

    const checkAccess = useCallback(() => {
        const access_token = getCookie('access_token');
        const refresh_token = getCookie('refresh_token');
        if (!access_token && !refresh_token) {
            clearAuth();
            return;
        }
        if (!navigator.onLine) return;
        fetch('/auth/account/me', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json;charset=UTF-8',
            },
        })
            .then((response) => {
                if (response.status === 401 || response.status === 403) {
                    if (refresh_token) return exchangeTokenWithRetry(refresh_token);
                    throw new Error('Auth error');
                }
                if (!response.ok) throw new Error('Server error');
                return response.json();
            })
            .then((data) => {
                if (data && data.username) {
                    setCookie('username', data.username);
                    setUsername(data.username);
                    setAuthorities(data.authorities);
                    setSignInDisplay('none');
                    setUsernameDisplay('flex');
                }
            })
            .catch((err) => {
                if (err.name === 'AbortError') return;
                if (err.message === 'Auth error') clearAuth();
                console.error(err);
            });
    }, [clearAuth, exchangeTokenWithRetry]);

    useEffect(() => {
        const access_token = getCookie('access_token');
        const refresh_token = getCookie('refresh_token');
        const cookieUsername = getCookie('username');
        if ((access_token || refresh_token) && cookieUsername) {
            setUsername(cookieUsername);
            setSignInDisplay('none');
            setUsernameDisplay('flex');
            checkAccess();
        } else {
            setUsername(cookieUsername);
            setSignInDisplay('block');
            setUsernameDisplay('none');
        }
        const interval = setInterval(checkAccess, 5 * 60 * 1000);
        return () => {
            clearInterval(interval);
        };
    }, [checkAccess]);

    const handleClick = () => {
        clearAuth();
    };

    const admin = authorities.includes('ADMIN') || authorities.includes('MANAGER');
    const logoClass = PlatformConfigurator.getLogoClass();
    const {origin} = window.location;

    return (
        <header>
            <div className="top_header">
                <div className="logoWrapper">
                    <Link className={`logo ${logoClass}`} to="/">
                        Home
                    </Link>
                </div>
                <Menu />
                <MobileMenu />
                <div
                    className="langWrapper"
                    style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                >
                    <a
                        style={{display: signInDisplay}}
                        className="myAccountLink"
                        rel="noopener noreferrer"
                        href={`${origin}/personalpage/login`}
                        target="_self"
                    >
                        <TranslatableText textKey="account" />
                    </a>
                    <div className="usernameLinkWrapper" style={{display: usernameDisplay}}>
                        <span className="usernameLink">{username}</span>
                        <span className="usernameIcon">
                            <FontAwesomeIcon fixedWidth icon={faUser} size="sm" />
                        </span>
                        <span className="chevron" />
                        <ul>
                            <li className="usernameHeader">
                                <span style={{color: '#d66d36'}}>{username}</span>
                            </li>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    href={`${origin}/personalpage/homepage`}
                                    target="_self"
                                >
                                    <TranslatableText textKey="myAccount" />
                                </a>
                            </li>
                            {admin && (
                                <li>
                                    <a
                                        rel="noopener noreferrer"
                                        href={`${origin}/controlpanel/#statistics`}
                                        target="_self"
                                    >
                                        <TranslatableText textKey="controlpanel" />
                                    </a>
                                </li>
                            )}
                            <li>
                                <span onClick={handleClick}>
                                    <TranslatableText textKey="signOut" />
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
